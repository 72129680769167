import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';
import { SuccessModalData } from './success-toast.interface';

@Component({
  standalone: true,
  selector: 'sup-quick-list-success',
  templateUrl: './success-toast.component.html',
  imports: [CommonModule, FormsModule, TranslateModule, MatIconModule, MatButtonModule, MatDialogModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuccessToastComponent {
  snackBarRef = inject(MatSnackBarRef);

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public readonly data: SuccessModalData,
    private readonly matDialog: MatDialog,
  ) {}

  successData: SuccessModalData = this.data;

  close(): void {
    this.snackBarRef.dismiss();
  }

  onlyText() {
    return !this.successData.subText && !this.successData.link;
  }
}
