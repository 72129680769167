<div class="text-sup-secondary flex justify-between">
  <div class="flex gap-2 items-center">
    <mat-icon svgIcon="checkCircle"></mat-icon>
    <div class="flex" [ngClass]="onlyText() ? 'items-center' : 'flex-col'">
      <div>{{ successData.text | translate }}</div>
      <div class="text-xs">
        <span *ngIf="successData.subText">{{ successData.subText | translate }}</span>
        <a *ngIf="successData.link" [href]="successData.link.url" class="text-sup-primary">
          {{ successData.link.text | translate }}
        </a>
        <button *ngIf="successData.action" class="text-sup-primary indent-1" (click)="successData.action.action()">
          {{ successData.action.text | translate }}
        </button>
      </div>
    </div>
  </div>
  <button mat-icon-button type="button" (click)="close()" type="button">
    <mat-icon>close</mat-icon>
  </button>
</div>
